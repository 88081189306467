<template>
  <div class="Privacy_container">
    <div class="content">
      <h1 class="common_title">Aviso de Privacidad</h1>
      <p>QOHOR Network, S.A. de C.V. (“QOHOR” o la “Sociedad”), en cumplimiento a lo dispuesto por la Ley Federal de Protección de Datos Personales en Posesión de los Particulares (en adelante la “Ley de Datos”) y su Reglamento, hace de su conocimiento el presente aviso de privacidad (el “Aviso de Privacidad”) por lo que se entiende que usted (el “Usuario”) ha revisado, leído y aceptado los términos de este, por lo que otorga su consentimiento para el tratamiento (el “Tratamiento”) de sus datos personales (los “Datos Personales”) por parte de QOHOR conforme a lo aquí establecido.</p>
      <p> Este Aviso de Privacidad aplica para el sitio web https://tifi.com.mx/, así como a los sitios, aplicaciones y herramientas relacionadas con nuestros servicios (en adelante “las Aplicaciones”).  Este Aviso aplica para clientes o usuarios de nuestros servicios o simplemente cualquier persona que utilice las Aplicaciones junto con cualesquier Términos y Condiciones, Políticas de Cookies o Términos de Uso emitidos por QOHOR. QOHOR se reserva el derecho a expedir políticas adicionales relacionadas con partes relacionadas como empleados, proveedores, socios comerciales, subsidiarias, afiliadas, entre otros.</p>
      <p>Usted como Titular de sus Datos personales, decide de forma voluntaria hacer uso de nuestros servicios mediante las Aplicaciones por lo que manifiesta su consentimiento libre e informado respecto al presente Aviso de Privacidad al momento de crear su Cuenta o utilizar cualquiera de nuestros servicios, contenidos, programas, promociones, o cualquier otra forma mediante la cual pueda acceder a nuestros servicios mediante las Aplicaciones.</p>
      <p>En caso de no aceptar el presente Aviso de Privacidad, la provisión de los servicios por parte de QOHOR será suspendida. </p>

      <h2>I.Responsable de los datos personales recabados y datos de contacto.</h2>
      <p>QOHOR Network, S.A. de C.V., con domicilio en Avenida Paseo de la Reforma 369, piso MZ, colonia Cuauhtémoc, alcaldía Cuauhtémoc, C.P. 06500 en la Ciudad de México, es el responsable de los datos personales que se recaban. QOHOR se encargará de recabar, manejar, procesar, tratar y conservar los Datos Personales de los particulares.</p>
      <p>Contacto TiFi, es la persona a quien puede contactar en relación con el tratamiento de sus datos personales, a través del correo electrónico: contacto@tifi.com.mx</p>

      <div class="p_margin_left">
        <h2>II.Términos esenciales.</h2>
        <p> -	Bases de datos: El conjunto ordenado de datos personales referentes a una persona identificada o identificable. </p>
        <p> -	Bloqueo: La identificación y conservación de datos personales una vez cumplida la finalidad para la cual fueron recabados, con el único propósito de determinar posibles responsabilidades en relación con su tratamiento, hasta el plazo de prescripción legal o contractual de estas. Durante dicho periodo, los datos personales no podrán ser objeto de tratamiento y transcurrido éste, se procederá  a su cancelación en la base de datos que corresponda. </p>
        <p> -	Consentimiento: Manifestación de la voluntad del titular de los datos mediante la cual se efectúa el tratamiento de los mismos. </p>
        <p> -	Cuenta: Se entiende como el acceso creado por el Usuario en el sitio: https://tifi.com.mx/</p>
        <p> -	Datos personales: Cualquier información concerniente a una persona física identificada o identificable. </p>
        <p> -	Datos personales sensibles: Aquellos datos personales que afecten a la esfera más íntima de su titular, o cuya utilización indebida pueda dar origen a discriminación o conlleve un riesgo grave para éste. En particular, se consideran sensibles aquellos que puedan revelar aspectos como origen racial o étnico, estado de salud presente y futuro, información genética, creencias religiosas, filosóficas y morales, afiliación sindical, opiniones políticas, preferencia sexual. </p>
        <p> -	Datos personales sensibles: Aquellos datos personales que afecten a la esfera más íntima de su titular, o cuya utilización indebida pueda dar origen a discriminación o conlleve un riesgo grave para éste. En particular, se consideran sensibles aquellos que puedan revelar aspectos como origen racial o étnico, estado de salud presente y futuro, información genética, creencias religiosas, filosóficas y morales, afiliación sindical, opiniones políticas, preferencia sexual. </p>
        <p> -	Sociedad:  Se entiende como QOHOR Network, S.A. de C.V.  </p>
        <p> -	Titular: La persona física a quien corresponden los datos personales. </p>
        <p> -	Tratamiento: La obtención, uso, divulgación o almacenamiento de datos personales, por cualquier medio. El uso abarca cualquier acción de acceso, manejo, aprovechamiento, transferencia o disposición de datos personales. </p>
        <p> -	Transferencia: Toda comunicación de datos realizada a persona distinta del responsable o encargado del tratamiento.</p>
      </div>

      <h2>III.Datos personales recabados.</h2>
      <p>Con el propósito de integrar los expedientes de los Usuarios de QOHOR, usted deberá proporcionar a QOHOR la siguiente información (los “Datos Personales Recabados”): </p>
      <p>Datos personales generales	La siguiente información: </p>
      <div class="p_margin_left">
        <p>i) Apellido paterno, apellido materno y nombre(s), o, en caso de ser extranjero, los apellidos completos que correspondan y nombre(s); </p>
        <p>ii) Fecha de nacimiento; </p>
        <p>iii) País de nacimiento; </p>
        <p>iv) País de nacionalidad; </p>
        <p>v) Domicilio en el lugar de residencia, compuesto de los siguientes datos: nombre de la calle, avenida o vía de que se trate, debidamente especificada; número exterior y, en su caso, interior; colonia o urbanización; demarcación territorial, municipio o demarcación política similar que corresponda, en su caso; ciudad o población, entidad federativa, estado, provincia, departamento o demarcación política similar que corresponda, en su caso; código postal y país; </p>
        <p>vi) Número(s) de teléfono, incluida la clave de larga distancia y, en su caso, extensión, cuando cuenten con aquél; </p>
        <p>vii) Correo electrónico, en su caso;</p>
        <p>viii) Clave Única de Registro de Población y la clave del Registro Federal de Contribuyentes, cuando cuente con ellas, y </p>
        <p>ix) Datos de la identificación con la que se identificó, consistentes en: nombre de la identificación; autoridad que la emite, y número de la misma. </p>
        <p>x) Datos recabados por mecanismos de rastreo.</p>
      </div>
      <p>Adicionalmente, tratándose de personas que tengan su lugar de residencia en el extranjero y a la vez cuenten con domicilio en territorio nacional en donde puedan recibir correspondencia dirigida a ellas, los datos relativos a dicho domicilio, con los mismos elementos que los contemplados en el numeral vi) anterior. </p>

      <p>Los siguientes documentos: </p>
      <div class="p_margin_left">
        <p>i) Identificación; </p>
        <p>ii) Constancia de la Clave Única de Registro de Población, expedida por la Secretaría de Gobernación o Cédula de Identificación Fiscal expedida por el SAT; </p>
        <p>iii) Comprobante de domicilio; </p>
        <p>Datos personales financieros y patrimoniales: Actividad, ocupación, profesión, datos de su cuenta bancaria de nómina, actividad o giro del negocio al que se dedique el Cliente o Usuario. </p>
      </div>
      <p>Datos personales sensibles QOHOR no recaba datos personales sensibles.</p>

      <h2>IV.Finalidad de los Datos Personales Recabados</h2>
      <p>Los Datos Personales Recabados serán utilizados por parte de la Sociedad para:</p>
      <p>Finalidad primaria</p>
      <p>Estas finalidades son imprescindibles, puesto que dan origen y resultan necesarias para la relación jurídica entre Usted y la Sociedad.</p>
      <p>Gestión de evaluación. Los Datos Personales Recabados que integran su expediente personal, se utilizarán para la atención de actividades de prospección, validación, detección y manejo de fraudes.</p>
      <p>Gestión de Operaciones. Una vez concluida la Gestión de Evaluación se determinará si el Usuario es susceptible de realizar operaciones con la Sociedad (las Operaciones). En dicho contexto, se remitirán los Datos Personales Recabados al vehículo legal que corresponda. Para efectos de lo anterior, se entenderá que el Usuario otorgó un mandato gratuito a favor de la Sociedad (el “Mandato”) para que QOHOR remitiera los datos, por lo que dicha remisión será en cumplimiento al Mandato.</p>
      <p>Gestión de Mantenimiento. En caso que el Usuario celebre Operaciones con QOHOR, los Datos Personales Recabados podrán ser usados para contratos, realizar gestiones de mantenimiento de las Operaciones, cumplimiento de obligaciones, ceder o enajenar, parcial o totalmente derechos sobre los contratos celebrados, así como administrar la relación contractual con el Usuario, incluyendo la Transferencia de los Datos Personales Recabados a Entidades Financieras mediante las cuales se realicen las Operaciones del Usuario, incluyendo todas aquellas transferencias que requiera el marco regulatorio aplicable.</p>
      <p>Para efectos de lo previsto en el Reglamento de la Ley de Datos, se informa al Usuario que el tratamiento de los Datos Personales Recabados puede llevarse como parte de un proceso de toma de decisiones sin que intervenga la valoración de una persona física. </p>

      <h2>V. Finalidades secundarias y Mecanismos para manifestar negativa.</h2>
      <p>Finalidades secundarias</p>
      <p>Estas finalidades son distintas a la que se señalan en el apartado Finalidad primaria que antecede, por lo que Usted podrá negar o revocar su consentimiento, así como oponerse a su tratamiento en relación con las finalidades secundarias, en términos del procedimiento descrito en el apartado IV, Acceso a Derechos ARCO siguiente. </p>
      <p>De manera adicional, los Datos Personales Recabados serán utilizados por QOHOR para las siguientes finalidades secundarias: (i) mercadotecnia, publicidad y prospección comercial; (ii) enviarle a los Usuarios información y documentación relativa a promociones, ofertas, publicidad e información de carácter mercadológico y publicitario, respeto de productos o servicios; (iii) para realizar análisis estadísticos, de generación de modelos de información y/o perfiles de comportamiento actual y predictivo; (iv) para participar en encuestas y promociones.</p>

      <h2>VI.Acceso a derechos ARCO.</h2>
      <p>Revocación del consentimiento, limitación de uso o divulgación de los Datos Personales Recabados y ejercicio de los derechos de acceso, rectificación, cancelación u oposición (ARCO).</p>
      <p>Usted, o su representante legal, podrá en cualquier momento: revocar su consentimiento para el tratamiento o uso de sus Datos Personales por parte de QOHOR, limitar su uso o divulgación, así como acceder a ellos, rectificarlos en caso de que sean inexactos o incompletos, cancelarlos y oponerse a su tratamiento en su totalidad o para ciertos fines. Para tales efectos, Usted deberá contactar a Contacto TiFi, a través del correo electrónico: contacto@tifi.com.mx</p>
      <p>Para  revocar su consentimiento para el tratamiento o uso de sus Datos Personales por parte de QOHOR, limitar su uso o divulgación, así como acceder a ellos, rectificarlos en caso de que sean inexactos o incompletos, cancelarlos y oponerse a su tratamiento en su totalidad o para ciertos fines, deberá a través de la solicitud, señalar expresa y detalladamente: (i) que desea revocar su consentimiento para el tratamiento o uso de sus datos personales; (ii) señalar la manera mediante la cual desea limitar el uso o divulgación de los datos personales; (iii) señalar la manera en que desean acceder o rectificar sus datos personales; (iv) señalar que desean cancelar sus Datos Personales; y/o (v) señalar que desean oponerse al tratamiento de sus Datos Personales.</p>
      <p>Asimismo, podrá cancelar sus Datos Personales, la cancelación dará lugar a un periodo de bloqueo el cual procederá a la supresión del dato. QOHOR podrá conservarlos exclusivamente para efectos de las responsabilidades nacidas del tratamiento. Una vez cancelado se le dará aviso al titular.</p>
      <p>Para ejercer los derechos ARCO antes descritos, usted deberá presentar su solicitud en términos del formato solicitud de ejercicio de derechos ARCO que podrá encontrar en el sitio web https://tifi.com.mx/ o en términos de la Ley de Datos. </p>

      <h2>VII.Uso de cookies, web beacons y similares</h2>
      <p>Cuando usted interactúa con el sitio web https://tifi.com.mx/, podemos recopilar registros del servidor, que pueden incluir información como la dirección IP del dispositivo, las fechas y horas de acceso, las características de la aplicación o las páginas visitadas, las caídas de la aplicación y otra actividad del sistema, el tipo de navegador y el sitio o servicio de terceros que estaba utilizando antes de interactuar con nuestros Servicios.</p>
      <p>Esto puede incluir datos técnicos de socios analíticos y publicitarios como Google, de bases de datos públicas, socios de verificación de identidad, revendedores y socios de canal, socios de marketing conjuntos y plataformas de medios sociales. </p>

      <h2>VIII.Procedimiento para comunicar los cambios al Aviso de Privacidad.</h2>
      <p>Todo cambio efectuado al presente Aviso de Privacidad estará disponible públicamente en el sitio web https://tifi.com.mx/</p>

      <h2>IX.Transferencia de los Datos Personales Recabados.</h2>
      <p>Los Datos Personales Recabados serán únicamente transferidos a las personas y con las finalidades que a continuación se describen, por lo que en términos del artículo 37, fracciones IV, V y VII de la Ley de Datos, no se requiere consentimiento alguno para en su caso, realizar dichas transferencias:</p>
      <p>TERCERO FINALIDAD</p>
      <p>Entidades Financieras En cumplimiento de las finalidades primarias, incluyendo la Transferencia de Datos Personales Recabados a favor de Entidades Financieras en cumplimiento y mantenimiento de la relación jurídica con el Usuario y QOHOR.Terceros En cumplimiento de las finalidades primarias. Prestadores de servicios En cumplimiento de las finalidades primarias. Autoridades En cumplimiento de leyes aplicables</p>
      <p>QOHOR podrá hacer remisiones y transferencias de los Datos Personales Recabados que podrán ser compartidos y tratados por QOHOR y/u otros miembros, subsidiarias o afiliadas del grupo de sociedades a la que pertenece QOHOR, así como cualesquier otros agentes, comisionistas, vendedores, asesores, proveedores o cualquier otro tercero que preste servicios a nombre de QOHOR. Dichos terceros tendrán acceso a los Datos Personales  únicamente para los fines necesarios para prestar su servicio siguiendo el principio de minimización y proporcionalidad establecido en la LFPDPPP y su Reglamento. Los terceros tienen prohibido usar los Datos Personales remitidos o transferidos para fines propios y deben adoptar las medidas de seguridad que sean consistentes con las medidas tomadas por QOHOR de conformidad con el presente Aviso y la legislación aplicable.</p>
      <p>En caso de que una empresa adquiera a QOHOR o exista un proceso corporativo mediante el cual se transfieran los activos, pasivos y capital (p.ej. Fusión o Escisión) o se reestructure o reorganice el negocio, se tendrán que compartir y transferir los Datos Personales para cumplir con una obligación legal y contractual, sin embargo, se tomarán las medidas físicas, administrativas y técnicas correspondientes para salvaguardar la información.</p>

      <h2>X.Conservación</h2>
      <p>QOHOR conservará la información durante el tiempo que sea necesario para lograr los fines por los cuales se inició el tratamiento. Los plazos de conservación se determinan según el tipo de registro, la naturaleza de la actividad, las obligaciones legales o regulatorias aplicables y las obligaciones contractuales correspondientes. </p>
      <p>El plazo usual de conservación es de 5 años, pero si los Datos Personales se recopilan en virtud de un contrato, se conservan 6 años después de la terminación del mismo para combatir y defender cualquier reclamación promovida en contra de QOHOR.  </p>
      <p>QOHOR podrá conservar los Datos personales durante más tiempo siempre que sea estrictamente necesario para proteger los intereses de la empresa o de nuestros clientes o así sea requerido por mandamiento judicial emitido por autoridad competente. </p>

      <h2>XI.Medidas de Seguridad</h2>
      <p>De conformidad con el artículo 19 de la LFPDPPP, en QOHOR establecimos medidas de seguridad físicas, técnicas y administrativas para proteger los Datos personales de nuestros clientes contra daño, pérdida, alteración, destrucción o el uso, acceso o tratamiento no autorizado de los mismos.</p>
      <p>En QOHOR aseguramos las bases de datos relacionadas con los servicios y mantenemos estricto control y confidencialidad de los Datos Personales Recabados, mismos que son proporcionados por nuestros clientes.</p>
      <p>QOHOR limita el acceso a los Datos Personales Recabados únicamente a los empleados, agentes, proveedores, contratistas o terceros que tengas necesidad de conocerlos para poder desempeñar de manera debida sus servicios. Los sujetos referidos tratarán los Datos personales bajo las instrucciones de QOHOR y están sujetos a la obligación de confidencialidad y cumplimiento de la normativa en materia de datos personales. </p>

      <h2>XII.Asociación de cuentas</h2>
      <p>Existen ocasiones en donde puede existir Transferencia de Datos Personales en virtud de una asociación o conexión de cuentas, en estos casos siempre será necesario su consentimiento para tal transferencia. La transferencia de Datos Personales será recíproca, nosotros adquiriremos datos personales de dicha cuenta asociada y ellos adquirirán datos de nuestra plataforma, lo anterior siempre de acuerdo a las políticas de privacidad de la cuenta asociada y nuestras políticas de privacidad. </p>
      <p>Algunos tipos de asociaciones de cuentas que pueden existir son: </p>
      <div class="p_margin_left">
        <p> -	Asociación de cuenta con redes sociales (v.gr. Facebook, Twitter, LinkedIn, Instagram, Whatsapp, etc.) </p>
        <p> - Asociación de cuenta con sus entidades financieras (v.gr. Bancomer Móvil, Santander Móvil, etc) 	</p>
        <p> -	Asociación de cuenta para pago (v.gr. Samsung Pay, Apple Pay, Paypal, etc.) </p>
      </div>

      <h2>XIII.Contacto</h2>
      <p>Puede comunicarse con nosotros para cualquier duda, aclaración o ejercicio de sus derechos al correo establecido para tal efecto: contacto@tifi.com.mx</p>
      <p>Otorgo mi consentimiento para el tratamiento que haga QOHOR de mis Datos Personales Recabados para las finalidades que se describen en este Aviso, de manera expresa y particularmente, por lo que hace a mis datos financieros y patrimoniales. </p>
      <p>Última actualización de este Aviso: 18 de Agosto, 2021. </p>
    </div>
  </div>
</template>

<script>
export default {
  props: {

  },
  data () {
    return {

    }
  },
  created () {

  },
}
</script>

<style scoped lang='scss'>
.Privacy_container {
  width: 100%;
  .content {
    padding: 60px 0px 45px;
    width: 86%;
    margin: 0 auto;
    // padding: 60px 0 50px 0;
    h1 {
      word-break: break-word;
      color: var(--primary-blue);
    }
    h2 {
      word-break: break-word;
      margin-top: 20px;
      font-family: 'Poppins ExtraBold 800', sans-serif;
      color: var(--primary-blue);
      font-size: 22px;
      font-weight: bold;
    }
    p {
      word-break: break-word;
      font-size: 22px;
      color: var(--primary-blue);
      font-family: 'Poppins SemiBold 600 Italic', sans-serif;
      padding-left: 0;
      padding-right: 0;
      line-height: 30px;
    }
    .p_margin_left {
      div {
        font-size: 22px;
        color: var(--primary-blue);
        font-family: 'Poppins SemiBold 600 Italic', sans-serif;
        padding-left: 0;
        padding-right: 0;
        line-height: 45px;
      }
      p {
        margin-left: 30px;
      }
    }
  }

}

@media screen and (max-width: 1246px) {
  .Privacy_container {
    width: 100%;
    .content {
      padding: 60px 0 45px;
      padding-left: 60px;
      width: 86%;
      margin: 0 auto;
      h1 {
        margin: 40px 0;
      }
      p {
        line-height: 40px;
      }
    }
  }
}
</style>
